import { useMarket } from "../../../../../common/contexts/MarketContext";
import { useSearchParams } from "react-router-dom";
import createPriceArray from "../../../../pages/app/marketplace/helpers/createPriceArray";
import useGenerateCurrentProductsList from "./useGenerateCurrentProductsList";

export default function useDetermineDowngradeText() {
    const { state, billingDateYear, billingDateMonth } = useMarket();
    const [searchParams] = useSearchParams();
    const prices = createPriceArray(state, searchParams);

    const generateCurrentProducts = useGenerateCurrentProductsList();
    const currentProducts = generateCurrentProducts(prices);

    return (price: undefined | string, priceId: string) => {
        const currentPrice = prices.find((price: {priceId: string}) => price.priceId === currentProducts?.[prices[0].productId])

        const priceBeingPurchased = prices.find(
            (_price: { priceId: string }) => {
                return _price.priceId === priceId;
            }
        );

        const billingDate = currentPrice.metadata.monthlyEquivalent ? billingDateYear : billingDateMonth;

        const sameInterval =
            !currentPrice || !priceBeingPurchased
                ? true
                : currentPrice?.isAnnual === priceBeingPurchased?.isAnnual;

        if (sameInterval) {
            return (
                <>
                    Are you sure you would like to downgrade this package? You will see these
                    changes reflected on the next billing cycle. This can be reversed before {billingDate}
                </>
            );
        } else {
            return (
                <>
                    Are you sure you would like to downgrade this package? This will result in
                    credit being added to your account for time remaining on your current package.
                    You will be charged {price}.
                </>
            );
        }
    };
}
