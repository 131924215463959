import useUserDetails from "../../../../../common/hooks/useUserDetails";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {collection} from "firebase/firestore";
import {firebase_firestore} from "../../../../../config/firebaseConfig";
import useFindProductId from "./useFindProductId";

export default function useGenerateCurrentProductsList() {
    const {user} = useUserDetails();
    const [subscriptionsObject] = useCollectionData(collection(firebase_firestore, `usermeta/${user?.uid}/subscriptions`));
    const activeProducts: any = {};
    const findProductId = useFindProductId();

    return (prices: any[]) => {
        if (subscriptionsObject) {
            const tradeHouseMonth = subscriptionsObject?.find((th: any) => {
                return th.tradingHouse === 'greenchart'
            });
            const tradeHouseYear = subscriptionsObject?.find((th: any) => {
                return th.tradingHouse === 'greenchartYear'
            });

            prices.forEach((price: { priceId: string; }) => {
                const isSubscribedOnMonth = tradeHouseMonth?.products?.some((product: { priceId: any; metadata: { price: any; }; }) =>
                    product.priceId === price.priceId || price.priceId === product.metadata?.price
                );
                const isSubscribedOnYear = tradeHouseYear?.products?.some((product: { priceId: any; metadata: { price: any; }; }) =>
                    product.priceId === price.priceId || price.priceId === product.metadata?.price
                );
                // const isCourseOwned = userDetails?.products?.courses?.some((course: { priceId: any; }) =>
                //     course.priceId === price.priceId
                // );

                if (isSubscribedOnMonth || isSubscribedOnYear) { // || isCourseOwned
                    const productId = findProductId(price.priceId);
                    if (productId) {
                        activeProducts[productId] = price.priceId;
                    }
                }

            });
        }
        return activeProducts;
    }
}