import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    ThemeProvider,
    Typography,
} from "@mui/material";
import {MarketplaceItemsTheme} from "./MarketplaceItemsTheme";
import Paper from "@mui/material/Paper";
import {useMarket} from "../../../common/contexts/MarketContext";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import {useState} from "react";
import {isCurrentProduct} from "../../pages/app/marketplace/helpers/isCurrentProduct";
import getFeatureByPriceId from "./productFeatures/productFeaturesMap";
import useUserDetails from "../../../common/hooks/useUserDetails";
import useHasGTF from "../../../common/hooks/useHasGTF";
import MarketStatusMap from "../../../common/helpers/MarketStatusMap";

/*
  2) Modularize the code for marketplace. Cleaning up the code and making it more readable.
  3) Decrease load time and make improvements on workflow. Create a function to query individual price information given a product ID.
 */

interface Product {
    name: string;
    subname: string;
    price: number;
    features: string[];
    gtfFeatures: string[];
    metadata: any;
    freeTrialDuration: string;
    productId: string;
}

interface MarketPlaceItemProps {
    product: Product;
    buttonClick: any;
    buttonText: string;
    setAnnual: (isAnnual: boolean) => void;
    isAnnual: boolean;
    userSubscription: any;
    disableButton?: boolean;
}

// {price: string, name: string, description: string, featuresList: string[]}
export default function MarketPlaceItem(props: MarketPlaceItemProps) {
    const {product, buttonClick, buttonText, disableButton, userSubscription, isAnnual} = props;
    const { getProductPrice, checkCouponAppliesTo, applyCouponToPrice } = useMarket();

    const [open, setOpen] = useState(false);

    const hasGTF = useHasGTF();

    const {userDetails} = useUserDetails();

    const features = (hasGTF && product.metadata.gtfFeatures
        ? product.metadata.gtfFeatures : undefined )
        || product.metadata.features;

    function formatPrice(price: number) {
        let numberPrice: number = +price;
        numberPrice = numberPrice / 100;
        return '$' + numberPrice.toFixed(2);
    }

    // TODO map elsewhere, for more flexability when adding new options
    function getButtonProperties() {
        let color = ''
        let fontSize = '20px'

        switch (buttonText) {
            case MarketStatusMap.upgrade:
                color = '#17C10F';
                break;
            case MarketStatusMap.downgrade:
                color = 'lightcoral';
                break;
            case MarketStatusMap.purchase:
                color = '#17C10F';
                break;
            case 'Get Free Trial':
                color = '#17C10F';
                break;
            case MarketStatusMap.currentPriceTrial:
                color = '#00A1ff';
                fontSize = '16px';
                break;
            case MarketStatusMap.currentPrice:
                color = "#00A1ff"
                break;
        }
        return {color, fontSize: fontSize};
    }

    function displayButton() {
        if (!disableButton) {
            return (<Button
                onClick={buttonClick}
                sx={{
                    backgroundColor: getButtonProperties().color,
                    color: '#eee',
                    '&:hover': {
                        backgroundColor: getButtonProperties().color,
                        opacity: .8,
                    },
                    borderRadius: "30px",
                    fontSize: getButtonProperties().fontSize,
                    width: "204px",
                    height: "48px",
                }}>{buttonText}</Button>)
        } else {
            return (
                (<Button
                    onClick={buttonClick}
                    disabled
                    sx={{
                        backgroundColor: '#e5e5e5',
                        color: '#eee',
                        '&:hover': {
                            backgroundColor: '#e5e5e5',
                        },
                        borderRadius: "30px",
                        fontSize: getButtonProperties().fontSize,
                        width: "204px",
                        height: "48px",
                    }}>{buttonText}</Button>)
            )
        }
    }

    function renderPromotionalSubtitle() {
        if (product.metadata.annualSubtitle) {

            return ( <>
                <Typography
                    pt={1}
                    sx={{
                        marginBottom: '-22px',
                        marginTop: '-16px',
                        alignItems: "center",
                        textAlign: "center",
                    }}
                    variant={"h5"}
                >
                    {product.metadata.annualSubtitle}
                </Typography>
                {/*{ addCancelAnytimeText() }*/}
            </>)
        } else {
            return (<>
                <Typography
                    pt={1}
                    sx={{
                        alignItems: "center",
                        textAlign: "center",
                    }}
                    variant={"h5"}
                >
                    {product.freeTrialDuration && isCurrentProduct(buttonText) ? 'Free Trial Active!' : product.freeTrialDuration}
                </Typography>
                {/*{ addCancelAnytimeText() }*/}
                </>)
        }

        // function addCancelAnytimeText() {
        //     if (!product.freeTrial) {
        //         return;
        //     }
        //
        //     return (
        //         <Typography
        //             sx={{
        //                 alignItems: "center",
        //                 textAlign: "center",
        //             }}
        //             variant={"h2"}
        //         >
        //             Cancel anytime
        //         </Typography>
        //     );
        // }

    }

    // function renderFeaturesTitle() {
    //     return (
    //         <Typography variant="h6" sx={{paddingTop: '16px'}} >Features</Typography>
    //     );
    // }

    function generateRequiredProductsList() {
        if (!product.metadata.requiredProduct || !product.metadata.requiredProduct.length) return [];

        // The requiredProducts are technically an array, but will require some tweaking for multiple requiredSubtitle if ever needed.
        let requiredProductsPrices = product.metadata.requiredProduct.split(',').map((id: string) => {
            const [productId, priceId] = id.split(':');
            const productPrice = getProductPrice(productId, priceId);
            if (isAnnual) {
                return getProductPrice(productId, product.metadata?.annualEquivalent);
            }

            return productPrice;
        });

        if (userSubscription && userSubscription.length) {
            const userProducts = userSubscription[0].products;

            // Go through user's purchased products and remove them from the requiredProductsPrices array.
            for (let userProduct of userProducts) {
                requiredProductsPrices = requiredProductsPrices.filter((product: any) => {
                    if (!product) return {};

                    // match current price
                    const currentPriceMatch = product.priceId !== userProduct.priceId;
                    // match promo price
                    const promoPriceMatch = userProduct?.metadata.price ? product.priceId !== userProduct.metadata.price : true;
                    // match yearly price
                    const yearlyPriceMatch = userProduct?.metadata.annualEquivalent || userProduct?.metadata.monthlyEquivalent
                        ? product.priceId !== userProduct.metadata.annualEquivalent && product.priceId !== userProduct?.metadata.monthlyEquivalent
                        : true;

                    return currentPriceMatch && promoPriceMatch && yearlyPriceMatch;
                });
            }
        }

        return requiredProductsPrices;
    }

    function renderRequiredProducts() {
        if (!product.metadata.requiredProduct || !product.metadata.requiredProduct.length) return <></>;

        const requiredProductsPrices = generateRequiredProductsList()
        if (!requiredProductsPrices.length || !requiredProductsPrices[0]) return <></>;

        return (
            <Box sx={{alignItems: 'center', display: 'contents'}} pb={4} key={product.productId}>
                <Typography variant="h6" align="center">Required Products</Typography>
                {requiredProductsPrices.map((price: any) => (
                    <Box sx={{textAlign: 'center'}}>
                        <Typography sx={{
                            fontSize: '16px',
                            padding: '0px',
                            color: 'green' }} key={price.prodId}>{price.name} {price.subname}</Typography>
                        <Typography key={price.priceId} sx={{fontSize: '24px', paddingBottom: '16px'}}>{formatPrice(price.price)}{determinePeriodText(price)}</Typography>
                    </Box>
                ))}
            </Box>
        );
    }

    function renderGuarantee() {
        if (!product.metadata.guarantee) return <></>;

        const showGuaranteeDetails = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };

        return (
            <Box sx={{ textAlign: 'center', margin: '16px' }}>
                <Typography sx={{ fontSize: '18px', color: 'white' }}>{product.metadata.guaranteeTitle}</Typography>
                <Typography sx={{ fontSize: '12px', color: 'white' }}>{product.metadata.guarantee}</Typography>
                <Typography
                    sx={{
                        fontSize: '16px',
                        color: 'white',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        '&:hover': {
                            color: '#ddd' // You can choose your preferred color
                        }
                    }}
                    onClick={showGuaranteeDetails}
                >
                    {product.metadata.guaranteeDetailsTitle}
                </Typography>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        sx: { minWidth: '600px' }
                    }}>
                    <DialogTitle>{product.metadata.guaranteeDetailsTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {product.metadata.guaranteeDetailsPanelText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    }

    function determinePeriodText(product: any) {
        // @ts-ignore
        const annual = product.isAnnual;

        // @ts-ignore
        const oneTimePayment = product.type === 'one_time';

        if (annual) return '/yr';
        if (oneTimePayment) return '';
        else return '/mo';
    }

    function displayPrice() {
        const appliedToProduct = checkCouponAppliesTo(product.productId);
        const primaryFontSize = '54px';
        const secondaryFontSize = '48px';

        if (product.price === 0) {
            return <Typography display="inline" component="h3" variant="h3" sx={{fontSize: secondaryFontSize}}>{'Free'}</Typography>
        }

        if (appliedToProduct) { // TODO interpret if coupon applies to this produce/price
            return (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        position: 'relative',
                        '::after': {
                            content: '""',
                            position: 'absolute',
                            top: '50%',
                            left: '0',
                            right: '0',
                            borderBottom: `5px solid #ff5959`,
                        }
                    }}>
                        <Typography display="inline" component="h3" variant="h3" sx={{fontSize: secondaryFontSize}}>{formatPrice(product.price)}</Typography>
                        <Typography display="inline" component="h1" variant="h1" sx={{color:"#008000"}}>{determinePeriodText(product)}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                        <Typography display="inline" component="h3" variant="h3" sx={{fontSize: primaryFontSize}}>{formatPrice(applyCouponToPrice(product.price))}</Typography>
                        <Typography display="inline" component="h1" variant="h1" sx={{color:"#008000"}}>{determinePeriodText(product)}</Typography>
                    </Box>
                </Box>
            )
        }

        return <>
                <Typography display="inline" component="h3" variant="h3" sx={{fontSize: primaryFontSize}}>{formatPrice(product.price)}</Typography>
                <Typography display="inline" component="h1" variant="h1" sx={{color:"#008000"}}>{determinePeriodText(product)}</Typography>
            </>
    }

    // These give the ability to modify the size of the name and subname
    function renderNameSize() {
        const defaultNameSize = '38px';
        return product.metadata.nameSize ? product.metadata.nameSize + 'px' : defaultNameSize;
    }

    function renderSubnameSize() {
        const defaultSubnameSize = '24px';
        return product.metadata.subnameSize ? product.metadata.subnameSize + 'px' : defaultSubnameSize;
    }

    const featuresList = () => {
        let tradingHouse = undefined;

        if (hasGTF) {
            tradingHouse = 'gtf';
        } else if (userDetails) {
            tradingHouse = 'greenchart';
        }

        const FeatureComponent = getFeatureByPriceId(product.name, tradingHouse);
        if (!FeatureComponent) return <></>;

        if (features) {
            return features?.split('\\').map((feature: string) => (
                <Box
                    key={feature}
                >
                    <Typography variant="subtitle1">• {feature}</Typography>
                </Box>)
            );
        }

        return (
            <Box
            >
                {/*@ts-ignore*/}
                {FeatureComponent ? <FeatureComponent/> : <div>No component available</div>}
                {/*<div dangerouslySetInnerHTML={{ __html: rawHTML }} />*/}
            </Box>
        );
    };

    return (
        <ThemeProvider theme={MarketplaceItemsTheme}>
            <Paper
                sx={{
                    maxWidth: '400px',
                    height: '100%', // Changed from 'auto' to '100%'
                    background: '#fcfcfc',
                    borderRadius: '12px',
                    border: isCurrentProduct(buttonText) ? `1px solid ${getButtonProperties().color}` : 'none',
                    position: 'relative',
                    display: 'flex', // Make Paper a flex container
                    flexDirection: 'column',
                }}
                elevation={2}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'start',
                        flexGrow: 1, // Allows this box to take up available space
                    }}
                >
                    {/* Content at the top */}
                    <Box m={2}>
                        <Box
                            sx={{
                                alignItems: "center",
                                textAlign: "center",
                            }}
                            mt={1}
                        >
                            <Typography
                                sx={{ fontSize: `${renderNameSize()}` }}
                                component="h3" variant="h4"
                            >
                                {product.name}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                alignItems: "center",
                                textAlign: "center",
                            }}
                        >
                            {/* Price */}
                            {displayPrice()}
                        </Box>
                        {renderPromotionalSubtitle()}
                    </Box>

                    {/* Content at the bottom */}
                    <Box m={2}>
                        <Box
                            pb={2}
                            sx={{
                                alignItems: "center",
                                textAlign: "center",
                            }}
                        >
                            {displayButton()}
                        </Box>
                        <Box
                            sx={{
                                alignItems: "center",
                                textAlign: "center",
                            }}
                            mt={1}
                        >
                            <Typography sx={{
                                fontSize: `${renderSubnameSize()}`,
                                padding: '0px',
                                color: 'green',
                                fontWeight: 'bold',
                            }}>
                                {product.subname || ' '}
                            </Typography>
                        </Box>
                        <Box sx={{ alignItems: "left" }}>
                            {featuresList()}
                        </Box>
                    </Box>

                    {/* Required Products */}
                    {renderRequiredProducts()}

                    {/* Guarantee */}
                    <Box
                        sx={{
                            borderRadius: "0 0 20px 20px",
                            backgroundColor: '#00A1ff',
                        }}
                    >
                        {renderGuarantee()}
                    </Box>

                    {/* Extra Marketing Button */}
                    {
                        product.freeTrialDuration && !isAnnual ?
                            <Box
                                mb={4}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box sx={{
                                    position: "absolute",
                                    bottom: 0,
                                    margin: "8px",
                                }}>
                                    <Button
                                        onClick={buttonClick}
                                        sx={{
                                            backgroundColor: getButtonProperties().color,
                                            color: '#eee',
                                            '&:hover': {
                                                backgroundColor: getButtonProperties().color,
                                                opacity: .8,
                                            },
                                            borderRadius: "30px",
                                            fontSize: "12px",
                                            width: "150px",
                                            height: "auto",
                                        }}
                                    >
                                        {product.freeTrialDuration}
                                    </Button>
                                </Box>
                            </Box> : ''
                    }
                </Box>
            </Paper>
        </ThemeProvider>
    );
}
