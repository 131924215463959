import React, {useContext} from 'react';
import {Box} from "@mui/material";
import Header from "../../../common/components/Header";
import {Routes, Route, Navigate, useSearchParams} from "react-router-dom";
import Marketplace from "./Marketplace";
import Profile from "./Profile";
import {GeneralContext} from "../../../common/contexts/GeneralContext";
import StrategySuite from "./StrategySuite";
import EconomicCalendar from "./EconomicCalendar";

export default function MembershipPortal() {
    const {preserveUrlParams} = useContext(GeneralContext);
    const [searchParams] = useSearchParams();

    return (<>
        <Box>
           <Header/>
            <Box>
                <Routes>
                    <Route path='/' element={ <Navigate to={`/dashboard${preserveUrlParams(searchParams)}`} /> }/>
                    <Route path="/marketplace" element={
                        <Marketplace/>
                    }/>
                    <Route path="/account" element={
                        <Profile/>
                    }/>
                    {/*<Route path="/changePassword" element={*/}
                    {/*    <ChangePassword/>*/}
                    {/*}/>*/}
                    <Route path="/strategy-suite" element={
                        <StrategySuite/>
                    }/>
                    <Route path="/economic-calendar" element={
                        <EconomicCalendar/>
                    }/>
                </Routes>
            </Box>
         </Box>
    </>)
}
